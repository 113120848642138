@charset "utf-8";
// 管理用　ここの値は基本的に触らない
$color_primary: #083388 !default;
$color_text: #1b1b1b !default;
$design_concept: 'material' !default;
$container: 1200 !default;
// 設定ファイルここから
$th03_bnr_break_point: $container; //コンテナのmax-width
$th03_bnr_img_width: 960; //画像のwidth
$th03_bnr_img_height: 430; //画像のheight
$th03_bnr_bg: rgba($color_black, .5);
$th03_bnr_text_color: #fff;
$th03_bnr_distance: 13; //マイナスすると上下逆になる
.th03_bnr {
    section{
        margin-bottom: 10%;
    }
	.th03_bnr_text {
		padding: 1.5em;
		color: $th03_bnr_text_color;
		background: $th03_bnr_bg url(/wp-content/uploads/bg_squere.png) center bottom repeat-x;
		background-size: 80%;
		h2, h3 {
			color: $color_primary;
            margin-bottom: 0;
			&:before, &:after {
				content: none;
			}
		}
	}
	.th03_bnr_button {
		&.more {
			a {
				color: $th03_bnr_text_color;
				border: 1px solid $th03_bnr_text_color;
				&:after {
					border-color: $th03_bnr_text_color;
				}
			}
		}
	}
}



@media ( min-width: 768px) {
	.th03_bnr {
		position: relative;
		+.th03_bnr {
			margin-top: 12%;
		}
		.th03_bnr_img {
			position: absolute;
			left: 45%;
			right: auto;
			top: 0;
			margin: auto;
			overflow: visible;
			padding-top: ($th03_bnr_img_height / $th03_bnr_break_point)*100+ %;
			>* {
				position: absolute;
				top: 0;
				right: -100%;
				left: 0;
				text-align: left;
			}
		}
		.th03_bnr_text {
			position: relative;
			width: 55%;
			@media (max-width: 900px) {
				width: 80%;
			}
			//overflow: hidden;
			$val: $th03_bnr_img_height+px;
			min-height: calc(#{$val} * 1);
			background-size: auto;
			p:not(.th03_bnr_button) {
				line-height: 2;
				margin-bottom: 4em;
				margin-right: 120px;
			}
			h2 {
				position: absolute;
				right: -20px;
				top: -20px;
			}
		}
		.th03_bnr_button {
			position: absolute;
			bottom: 7em;
			left: 1.5em;
		}
		.reverse {
			.th03_bnr_img {
				left: auto;
				right: 45%;
				>* {
					right: 0;
					left: -100%;
					text-align: right;
				}
			}
		}
		&.wow {
			.th03_bnr_img {
				opacity: 0;
				transition: 1s .5s;
			}
			.th03_bnr_text {
				opacity: 0;
				transition: 1s .5s;
			}
		}
		&:not(.wow), &.animated {
			.th03_bnr_img {
				transform: translate(0, (0 - $th03_bnr_distance)+px);
				opacity: 1;
			}
			.th03_bnr_text {
				transform: translate(0, $th03_bnr_distance+px);
				opacity: 1;
			}
		}
	}
}