@charset "utf-8";

$dots-color: #ccc;
$dots-active-color: $color_primary;

// main_visual
.main_visual {
  position: relative;
  @if $main_slide_fade == true {
    table-layout: fixed;
  }
  @if $main_slide_arrows == true {
    list-style-position: inside;
  }
  @if $main_slide_dots == true {
    list-style-type: circle;
  }
  .main_visual_slick,
  .slick-track {
    @include listclear();
    height: 100%!important;
  }
  .slick-track {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .slick-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }
  .main_visual_slick {
    position: relative;
    margin: 0 auto;
    li {
      @include position-center();
      overflow: hidden;
       &:not(.slick-active) {
        transition-delay: .5s!important;
       }
      img {
        @include position-center-wide();
        top: 0;
        height: 100%;
      }
    }
  }
  .main_visual_copy {
    @include position-center();
    @include listclear();
    width: $container+px;
    max-width: 100%;
    margin: 0 auto;
    z-index: 53;
    @if $main_slide_copy_sync != false {
      transform: scale(1);
    }
    >li {
      @include position-center();
      @if $main_slide_copy_sync != false {
        display: none;
      }
      img {
        @include position-center();
        width: ($main_slide_copy_width / $container)*100+%;
        max-width: $main_slide_copy_width+px;
        max-height: $main_slide_copy_height+px;
        z-index: 52;
        left: 3%;
        right: auto;
      }
    }
  }
  .main_visual_arrow {
    @include position-center();
    width: $container+px;
    max-width: 100%;
    margin: 0 auto;
    z-index: 53;
    .slick-prev {
      left: 25px;
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev:before,
    .slick-next:before {
      @include fontsize(30);
    }
    @media ( max-width : $main_slide_break_point+px ) {
      .slick-prev {
        left: 10px;
      }
      .slick-next {
        right: 10px;
      }
      .slick-prev:before,
      .slick-next:before {
        @include fontsize(20);
      }
    }
  }
  &.wow {
    .main_visual_copy {
      >li {
        img {
          &.main_copy01 {
            //コピーのアニメーション遷移”前”をここに書く
          }
        }
      }
    }
    &.animated {
      .main_visual_copy {
        >li {
          img {
            &.main_copy01 {
              //コピーのアニメーション遷移”後”をここに書く
            }
          }
        }
      }
    }
  }
}
@media ( max-width : $main_slide_break_point+px ) {
  .main_visual {
    // margin-top: 110px;
    .main_visual_copy {
      >li {
        img {
          width: 50%;
        }
      }
    }
  }
}

@if $main_slide_type == 'A' {
  .main_visual {
    .main_visual_slick {
      padding-top: ($main_slide_height / $main_slide_width)*100 + %!important;
    }
  }
  @media ( max-width : 1000px ) {
    .main_visual {
      .main_visual_slick {
        padding-top: (($main_slide_height / $main_slide_width)*100)*1.5 + %!important;;
      }
    }
  }
  @media ( max-width : 768px ) {
    .main_visual {
      .main_visual_copy {
        >li {
          img {
            width: 43%;
          }
        }
      }
    }
  }
  @media ( max-width : 600px ) {
    .main_visual {
      .main_visual_copy {
        >li {
          img {
            width: 40%;
          }
        }
      }
    }
  }
  @media ( max-width : $main_slide_break_point+px ) {
    .main_visual {
      .main_visual_slick {
        padding-top: (($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %!important;;
      }
    }
  }
}

@if $main_slide_type == 'B' {
  body.home {
    padding-top: 0!important;
  }
  header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 52;
  }
  body {
    &.tablet,
    &.mobile {
      .main_visual {
        .main_visual_slick {
          padding-top: 100%!important;
        }
      }
    }
    &:not(.tablet):not(.mobile) {
      .main_visual {
        .main_visual_slick {
          padding-top: 100vh!important;
        }
      }
    }
  }
  @media ( min-aspect-ratio : $main_slide_width+'/'+$main_slide_height ) {
    .main_visual {
      .main_visual_slick {
        li {
          img {
            width: 100%;
            height: auto!important;
          }
        }
      }
    }
  }
}

@if $main_slide_type == 'C' {
  .main_visual {
    .main_visual_slick {
      padding-top: ($main_slide_height / $container)*100 + %!important;;
    }
  }
  @media (min-width: $container+px) {
    .main_visual {
      .main_visual_slick {
        padding-top: 0!important;;
        height: $main_slide_height+px!important;;
      }
    }
  }
  @media (min-width: $main_slide_width+px) {
    .main_visual {
      .main_visual_slick {
        padding-top: ($main_slide_height / $main_slide_width)*100 + %!important;;
      }
    }
  }
  @media ( max-width : $main_slide_break_point+px ) {
    .main_visual {
      .main_visual_slick {
        padding-top: (($main_slide_height / $container)*100)*$main_slide_correct + %!important;;
      }
    }
  }
}

@if $main_slide_type == 'D' {

  $main_slide_type_main: 80; // %
  $main_slide_type_thumbnail: (100-$main_slide_type_main); // %
  $main_slide_type_result: ($main_slide_height / $main_slide_width)*400/$main_slide_type_count;

  // main_visual
  .main_visual {
    position: relative;
    overflow: hidden;
    transform: scale(1);
    .main_visual_slick {
      @include listclear();
      position: relative;
      padding-top: ($main_slide_height / $main_slide_width)*100 + %;
      li {
        img {
          @include position-center-wide();
          top: 0;
          height: 100%;
        }
      }
    }
    .main_visual_slick_thumb {
      @include listclear();
      letter-spacing: -.40em;
      >li {
        letter-spacing: normal;
        vertical-align: top;
        display: inline-block;
        width: 100/$main_slide_type_count+%;
        position: relative;
        overflow: hidden;
        padding-top: ($main_slide_height / $main_slide_width)*100/$main_slide_type_count + %;
        cursor: pointer;
        user-select: none;
        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: -300%;
          right: -300%;
          margin: auto;
          @if $main_slide_type_count > 4 {
            height: auto;
            width: 100%;
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background: #000;
          opacity: .3;
          width: 100%;
          height: 100%;
          z-index: 1;
          transition: .3s;
        }
        &.main_visual_slick_thumb_current {
          &:after {
            opacity: 0;
          }
        }
      }
    }
  }
  @media ( max-width : $main_slide_break_point+px ) {
    .main_visual {
      .main_visual_slick {
        padding-top: (($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
      }
      .main_visual_copy {
        >li {
          img {
            width: 70%;
          }
        }
      }
    }
  }
  @media (min-width: $header_break_point+px) {
    .main_visual {
      >* {
        @if $main_slide_type_thumbnail_position == 'left' {
          float: right;
        } @else {
          float: left;
        }
      }
      .main_visual_slick,
      .main_visual_copy {
        width: $main_slide_type_main+%;
      }
      .main_visual_copy {
        @if $main_slide_type_thumbnail_position == 'left' {
          left: auto;
        } @else {
          right: auto;
        }
      }
      .main_visual_slick_thumb {
        width: $main_slide_type_thumbnail+%;
        >li {
          display: block;
          width: 100%;
          padding-top: $main_slide_type_result+%;
        }
      }
    }

  }
}

.slick-dots {
  li {
    button {
      margin: 0 auto;
      background: $dots-color;
    }
    &.slick-active {
      button {
        background: $dots-active-color;
      }
    }
  }
}

.slide_scroll {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 45px;
  background: url(/wp-content/uploads/icon_scroll.png) center bottom no-repeat;
  z-index: 55;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}
