@charset "UTF-8";

.button {
  display: inline-block;
  min-width: $button_minwidth;
  margin: 0;
  padding: 1em 1em;
  border: $style_button_border;
  border-radius: $style_button_border_radius;
  line-height: 1;
  background: $color_button_bg;
  color: $color_button_text;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;

  &:hover {
    background: $color_button_bg_hover;
    color: $color_button_text_hover;
  }

  .highlight & {
    color: $color_button_text;
    &:hover {
      color: $color_button_text_hover;
    }
  }

  &.block {
    display: block;
    min-width: 0;
  }

  &.inverse {
    border: $style_button_inverse_border;
    border-radius: $style_button_inverse_border_radius;
    background: $color_button_inverse_bg;
    color: $color_button_inverse_text;
    &:hover {
      background: $color_button_inverse_bg_hover;
      color: $color_button_inverse_text_hover;
    }
  }

  &.cancel {
    background: $color_button_disable_bg;
    color: $color_button_disable_text;
    &:hover {
      background: $color_button_disable_bg_hover;
    }
  }

  &.large {
    padding: 1em 2em;
    @include fontsize(16);
  }

  + .button {
    margin-left: 0.5em;
  }

  // 矢印アイコン(icon mũi tên)
  span {
    line-height: 1;
    @if $button_arrow == 'left' {
      background-size: auto 14px;
    }
    @if $button_arrow == 'right' {
      background-size: 9px auto;
    }
  }

  // hoverでアイコンを変更する場合(trường hợp thay đổi icon bằng hover)
  // &:hover span {
  //   @if $button_arrow == 'left' {
  //     background-image: url(/wp-content/uploads/);
  //   }
  //   @if $button_arrow == 'right' {
  //     background-image: url(/wp-content/uploads/);
  //   }
  // }
}
