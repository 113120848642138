@charset "utf-8";
$wow: false;
h1:not(.title) {
	text-align: center;
    text-shadow: 0px 2px 6px #000000;
	position: relative;
	@include fontsize(36);
	font-weight: normal;
	padding-top: 40px;
	color: $color_h1_text;
	margin: 0em 0 .5em;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
  @media (max-width: 600px) {
    padding-top: 30px;
  }
//	&:after {
//		content: '';
//		display: block;
//		position: absolute;
//		bottom: 0;
//		left: 0;
//		right: 0;
//		margin: auto;
//		height: 4px;
//		background: $color_h1_text;
//		transition: 1s .5s;
//	}
	&.wow {
		@if $wow==true {
			opacity: 0;
			transition: .5s;
			&:after {
				width: 0;
			}
			&.animated {
				opacity: 1;
				&:after {
					width: 200px;
				}
			}
		}
	}
}

h2:not(.notstyle) {
	display: table;
	table-layout: fixed;
	padding-bottom: .4em;
	margin: 0 auto 1.5em;
	position: relative;
	border: 0;
	word-wrap: break-word;
	font-weight: normal;
	@include fontsize(24);
	color: $color_h2_text;
	@media ( max-width: 600px) {
		//width: 80%;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -169px;
		transform: translateY(-50%);
		height: 66px;
		width: 348px;
		background: url(/wp-content/uploads/icon_wave.png) 0 0 no-repeat;
        z-index: -1;
	}
}


@if $wow==true {
	h2 {
		&.animated {
			opacity: 1;
			&:after, &:before {
				opacity: 1;
				width: 50vw;
			}
		}
	}
}
h3 {
	margin: 0 0 1.5em;
	padding-bottom: .7em;
	color: $color_h3_text;
	font-weight: normal;
	@include fontsize(18);
	position: relative;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 40px;
		height: 1px;
		border-bottom: 2px solid $color_h3_text;
	}
}
h4 {
	margin: 0 0 1.5em;
	padding-left: 15px;
	@include fontsize(16);
	position: relative;
	color: $color_h4_text;
	font-weight: normal;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		display: block;
		width: 6px;
		height: 1px;
		margin: auto;
		border-bottom: 2px solid $color_h4_text;
	}
}
.widget_wrap {
	.widget_header {
		@include fontsize(16);
		color: $color_text;
		padding: .7em 0 .7em 1em;
		background: rgba($color_primary, .05);
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
@media (max-width: 767px) {
	h1:not(.title) {
		@include fontsize(22);
		&:after {
			height: 2px;
		}
	}

	h2 {
		@include fontsize(18);
		&:after, &:before {
			height: 1px;
		}
	}

	h3 {
		@include fontsize(16);
	}

	h4 {
		@include fontsize(14);
	}

	.widget_wrap {
		.widget_header {
			@include fontsize(14);
		}
	}
}