@charset "utf-8";

// ======== All Device

.main_copy01{
    opacity: 0;
    transition: 1s 0.5s;
    transform: translateY(-20px);
    &.animated{
        opacity: 1;
        transform: translateY(0px);

    }
}
.mb0 {
  margin-bottom: 0;
}

.border1{
  padding: 2em 1em;
  border: 1px solid #fff;
  @media ( max-width: 767px) {
    padding: 1.2em .7em;
    text-align: left;
  }
}
// section[class^="osake"]{
//   table.table-menu{
//     tbody{
//       tr{
//         @media ( min-width: 768px) {
//           td:first-child{
//             position: relative;
//             padding: 0.8em 0em 0.8em 1em;
//             > span.right{
//               position: absolute;
//               right: 0;
//               bottom: 11px;
//               width: 90px;
//               text-align: center;
//             }
//           }
//           td:last-child{
//             padding: 0.8em 1em 0.8em 0em;
//             width: 70px;
//           }
//         }
//       }
//     }
//   }
// }

section[class^="osake"],
section[class^="drink"]{
  span.text-red{
    margin-left: 10px;
  }
}

footer{
  padding: 50px 0;
  // background: url(/wp-content/uploads/bg_squere.png) 0 top repeat-x;
  background-image: url(/wp-content/uploads/bg_squere.png), url(/wp-content/uploads/body_bg.jpg),;
  background-position: 0 top, 0 0;
  background-repeat: repeat-x, repeat;
  // background-size: auto 120px;
  @media ( min-width: 768px) {
    padding: 120px 0 40px;
  }
}
.h1_wrap {
    background-attachment: fixed;
    height: 90px;
    @media ( min-width: 768px) {
        height: 150px;
    }
    @media ( min-width: 1920px) {
        background-size: cover;
    }
    &.h1_company{
        background: url(/wp-content/uploads/h1_bg001.jpg) top center no-repeat;
    }
    &.h1_food{
        background: url(/wp-content/uploads/h1_bg002.jpg) top center no-repeat;
    }
    &.h1_osake{
        background: url(/wp-content/uploads/h1_bg003.jpg) top center no-repeat;
    }
    &.h1_drink{
        background: url(/wp-content/uploads/h1_bg004.jpg) top center no-repeat;
    }
    &.h1_good{
        background: url(/wp-content/uploads/h1_bg00.jpg) top center no-repeat;
    }
    &.h1_contact{
        background: url(/wp-content/uploads/h1_bg005.jpg) top center no-repeat;
    }
    &.h1_policy{
        background: url(/wp-content/uploads/h1_bg006.jpg) top center no-repeat;
    }
}



// ======== Mobile
@media ( max-width : 480px ) {

} // @media

// ======== Tablet & PC
@media ( min-width : 768px ) {

} // @media

// ======== PC
@media ( min-width : 1024px ) {

} // @media
