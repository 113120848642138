@charset "utf-8";

footer {
  position: relative;
  background: $color_footer_bg;
  color: $color_footer_text;

  a {
    color: $color_ftnavlink;
    &:hover {
      color: $color_ftnavlink_hover;
    }
  }

  nav {
    text-align: center;
    ul {
      @include listclear();
      li {
        + li {
          border-top: 1px solid $color_ftnav_border_sp;
        }
      }
    }
    a {
      display: block;
      padding: .3em .3em;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .copyright {
    text-align: center;
    p {
      padding: 1em .3em;
    }
  }
}

@media ( min-width : 768px ) {

  footer {
    .row:not(:last-child) {
      margin-bottom: 5px;
    }
    nav {
      text-align: center;
      ul {
        @include list-horizontal();
        white-space: normal;
        li {
          line-height: 1.5;
          white-space: normal;
          + li {
            border: 0;
          }
          &:not(:first-child):before {
            content: "|";
            margin-right: .3em;
            margin-left: .3em;
          }
        }
      }
      a {
        display: inline;
        padding: 0;
      }
    }
  }

}

// スマホナビゲーション折り返し設定（タブレット）(thiết lập SP navigation 2 dòng.(tablet))
@media ( min-width : 768px ) and ( max-width: 1023px ) {
  footer nav ul li {
    &.tablet-break:after {
      content: "\A";
      white-space: pre;
    }
    &.tablet-break + li:before {
      display: none;
    }
  }
}

/*
 * フッターパターンA(footer pattern A)
 */
@if $footer_type == 'A' {
  @media ( min-width : 768px ) {
    footer {
      .container {
        .row {
          .col {
            float: none;
            width: 100%;
          }
          nav {
            padding: 1em 0 0;
            ul {
              display: inline-block;
            }
            a {
              display: inline-block;
              padding: .3em .3em;
            }
          }
        }
      }
    }
  }
}
/*
 * フッターパターンB(footer pattern B)
 */
@else if $footer_type == 'B' {
  @media ( min-width : 768px ) {
    footer {
      .container {
        .row {
          .col {
            float: none;
            width: 100%;
          }
          nav {
            text-align: center;
            ul {
              display: inline-block;
            }
            a {
              display: inline-block;
              padding: 1em .3em;
            }
          }
        }
      }
    }
  }
}
